/* RESET DEFAULTS */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* MY RULES */

/* high level */

@font-face {
    font-family: "Gilroy-Bold";
    src: url("../fonts/Gilroy-Bold.ttf");
}

@font-face {
    font-family: "Gilroy-BoldItalic";
    src: url("../fonts/Gilroy-BoldItalic.ttf");
}

@font-face {
    font-family: "Gilroy-SemiBold";
    src: url("../fonts/Gilroy-SemiBold.ttf");
}

@font-face {
    font-family: "Gilroy-SemiBoldItalic";
    src: url("../fonts/Gilroy-SemiBoldItalic.ttf");
}

@font-face {
    font-family: "Gilroy-Medium";
    src: url("../fonts/Gilroy-Medium.ttf");
}

@font-face {
    font-family: "Gilroy-MediumItalic";
    src: url("../fonts/Gilroy-MediumItalic.ttf");
}

@font-face {
    font-family: "Gilroy-Regular";
    src: url("../fonts/Gilroy-Regular.ttf");
}

@font-face {
    font-family: "Gilroy-RegularItalic";
    src: url("../fonts/Gilroy-RegularItalic.ttf");
}

@font-face {
    font-family: "Gilroy-Light";
    src: url("../fonts/Gilroy-Light.ttf");
}

@font-face {
    font-family: "Gilroy-LightItalic";
    src: url("../fonts/Gilroy-LightItalic.ttf");
}

.site-wrapper {
    display: flex;
    height: 100vh;
    width: 100%;
    background: rgb(247, 245, 243);
}

.left-column{
    overflow: hidden;
}

.color-block {
    position: fixed;
    height: 100%;
    width: 100%;
    right: 60%;
    bottom: 50%;
    transition: 300ms;
    transition-timing-function: cubic-bezier(1,.02,.38,.71);
}

.color-block.cb-down {
    right: -100%;
    bottom: -100%;
    display: flex;
}

.cb-blue {
    background: #D0E3F5;
}

.cb-white {
    background: rgb(247, 245, 243);
}

.z10 {
    z-index: 10;
}  

.z9 {
    z-index: 9;
}

.z8 {
    z-index: 8;
}  

.z7 {
    z-index: 7;  
}

.z6 {
    z-index: 6;   
}

.z5 {
    z-index: 5;
}

.z4 {
    z-index: 4;
}

.z3 {
    z-index: 3;
}

.z2 {
    z-index: 2;
}

.z1 {
    z-index: 1;
}  

.color-block.move-in {
    right: 0%;
    bottom: 0%;
}

.color-block.move-out {
    right: 100%;
    bottom: 100%;
}

.color-block.move-in-down {
    right: 0%;
    bottom: 0%;
}

.left-column {
    height: 100%;
    width: 40%;
}
.right-column {
    height: 100%;
    width: 60%;
    overflow: auto;
}

.top-left {
    height: 50%;
}

.bottom-left {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.name-space {
    width: max-content;
    height: max-content;
    padding-left: 4rem;
    padding-bottom: 1.4rem;
    font-family: 'Gilroy-Medium';
    font-size: 4rem;
}

.tag-line {
    padding-left: 4rem;
    font-family: 'Gilroy-Light';
    font-size: 1.5rem;
    user-select: none;
}

.links {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 8rem;
    font-size: 10rem;
    font-family: 'Gilroy-SemiBold';
    user-select: none;
}

.content-title {
    display: flex;
    flex-direction: column;
    padding-left: 8rem;
    font-size: 6.5rem;
    font-family: 'Gilroy-SemiBold';
    padding-bottom: 2rem;
    padding-top: 3rem;
    user-select: none;
    cursor: pointer;

}

.content-title:first-child{
    padding-top: 5rem;
}

.content-title:hover {
    text-decoration: underline;
    text-decoration-color: rgb(255, 255, 255);
}

.spacer {
    height: 6%;
}

.link-item {
    width: fit-content;
    cursor: pointer;
    transition: transform 200ms;
}

.link-item:hover {
    text-decoration: underline;
    text-decoration-color: rgba(35, 149, 255, 0.19);
    text-decoration-thickness: 8px;
    transform: scale(1.04,1.04);
}

.hidden {
    display: none;
}

.send-b-right {
    right: -100%;
    bottom: -100%;
}

.send-b-in.content-container {
    right: 0%;
    bottom: 0%;
}

.send-b-in.desc-title-container {
    right: 60%;
    bottom: 0%;
}

.p-fixed {
    position: fixed;
}

.content-container {
    width: 60%;
    height: 100%;
    overflow: auto;
    transition: 300ms;
    transition-timing-function: cubic-bezier(1,.02,.38,.71);
}

.desc-title-container {
    width: 40%;
    height: 50%;
    transition: 300ms;
    transition-timing-function: cubic-bezier(1,.02,.38,.71);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title-test {
    align-items: center;
}

.desc-title-content {
    width: max-content;
    height: 60%;
    padding-left: 4rem;
    font-family: 'Gilroy-Medium';
    font-size: 4rem; 
    user-select: none;
    align-items: end;
    display: flex;  
}

.content-section.content-hidden {
    max-height: 0px;
}

.content-section {
    overflow: hidden;
    /* transition: max-height .5s ease;
    max-height: 50000px; */
    background-color: #000000;
    display: flex;
    flex-direction: column;
}

.work-content-title-text {
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-family: 'Gilroy-Medium';
    font-size: 3.2rem;  
    color: #ffffff;
}

.work-content-text {
    padding-left: 8rem;
    padding-right: 8rem;
    padding-bottom: 3rem;
    font-family: 'Gilroy-Medium';
    font-size: 1.5rem;  
    color: #ffffff; 
}

.work-content-text a {
    color: #D0E3F5;
}

.work-content-table {
    display: flex;
    flex-direction: row;
    padding-left: 8rem;
    padding-bottom: 3rem;
    padding-right: 8rem;
}

.work-content-table-title {
    padding-bottom: 1.4rem;
    font-family: 'Gilroy-Medium';
    font-size: 1.5rem;  
    color: #ffffff; 
    text-decoration: underline;
}

.work-content-table-text {
    padding-bottom: 1.4rem;
    font-family: 'Gilroy-Medium';
    font-size: 1.5rem;  
    color: #ffffff; 
}

.content-table-item {
    display: flex;
    flex-direction: column;
    width: 33%;
    padding-right: 1.5rem;
}

.work-img {
    display: flex;
    justify-content: center;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 3rem;
}

.work-img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-color: #ffffff;
    border-style:solid;
    border-width: 2px;
    border-radius: 5px;
}

.tweet-img-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 3rem;
}

.content-title.title-selected {
    font-family: 'Gilroy-SemiBoldItalic';
    background-color: rgb(247, 245, 243);
}

.content-title.title-selected:hover {
    text-decoration: none;
}

.my-name {
    user-select: none;
    cursor:auto;
}

.about-img {
    display: flex;
    justify-content: center;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 3rem;
    padding-top: 2rem;
}

.about-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 1000px;
    max-width: 15rem;
}

.about-text {
    padding-left: 8rem;
    padding-right: 8rem;
    padding-bottom: 3rem;
    font-family: 'Gilroy-Medium';
    font-size: 1.5rem;  
}

.about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    min-height: 100%;
}

.resume-btn {
    width: 9rem;
    height: 3rem;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 200ms;
}

.resume-btn:hover{
    transform: scale(1.04,1.04);
}

.resume-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
}

.resume-btn-container a {
    text-decoration: none;
    color: #ffffff;
    font-family: 'Gilroy-Light';
    font-size: 1.8rem;
}

.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.contact-text {
    font-family: 'Gilroy-Medium';
    font-size: 1.5rem;  
}

.contact-item {
    margin-bottom: 3rem;
    background-color: #000000;
    width: 20rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 200ms;
}

.contact-item:hover{
    transform: scale(1.04,1.04);
}

.contact-item:last-child {
    margin-bottom: 0rem;
}

.contact-item a {
    color: #ffffff;
    width: 100%;
    height: 100%;
}

.contact-item-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.return-container {
    padding-left: 4rem;
    font-family: 'Gilroy-Regular';
    font-size: 1.5rem;
    user-select: none;
    height: 40%;
    align-items: end;
    display: flex;
    padding-bottom: 1rem;
}

.return-btn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    transition: transform 200ms;
}

.return-btn img {
    padding-right: .75rem;
}

.return-text {
    display: flex;
    align-items: center; 
}

.return-btn:hover {
    transform: scale(1.04,1.04);
}

.back-svg{
    height: 32px;
}

img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.floating-back-btn {
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) .5s;
    transform: scale(0,0);
}

.floating-bar {
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) .1s;
    transform: scale(1,0);
    display: none;
}

.password-wrapper {
    z-index: 20;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #596f85;
    display: flex;
    justify-content: center;
    align-items: center;
}

.password-modal {
    z-index: 30;
    width: 35rem;
    height: 15rem;
    background-color: #39536d;
    border-radius: 8px;
    filter: drop-shadow(0 4px 7px #00000040);
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
}

.password-desc {
    padding-top: 2rem;
    font-family: 'Gilroy-Medium';
    font-size: 2rem;
    color: #ffffff;
}

.lock-svg {
    height: 2rem;
}

.password-entry {
    width: 100%;
}

.password-entry input {
    border-radius: 8px 0px 0px 8px;
    width: 100%;
    font-family: 'Gilroy-Medium';
    font-size: 1.3rem;
    border: none;
    height: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
}

.password-entry input:focus {
    border: none;
    box-shadow: none;
    outline-offset: 0px;
    outline: none;
}

.form-area {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 3rem;
}

.password-submit-btn input {
    display: none;
}

.password-submit-btn {
    background-color: #0e1d2c;
    height: 30px;
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 8px 8px 0px;
    transition: all ease .5s;
    cursor: pointer;
}

.password-submit-btn:hover {
    background-color: #596f85;
}

.submit-svg {
    height: 20px;
    cursor: pointer;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.pass-label {
    display: flex;
    justify-content: center;
    align-items: center;
}

.password-main-form {
    width: 80%;
}

.password-error-msg {
    position: absolute;
    top: 35%;
    color: #ffd78c;
    font-family: 'Gilroy-Medium';
}

.password-wrapper.password-active {
    display: none;
}

@media (max-width: 920px) {
    html,
    body {
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
    }

    .site-wrapper {
        flex-direction: column;
        overflow: hidden;
    }
    .left-column {
        width: 100%;
        height: 30%;
        padding-left: 7vw;
        align-items: end;
        display: flex;
    }
    .right-column {
        width: 100%;
        height: 70%;
    }
    .top-left {
        display: none;
    }
    .name-space {
        /* z-index: 11; */
        padding-left: 0rem;
        font-size: 11vw;
    }
    .tag-line {
        /* z-index: 11; */
        padding-left: 0rem;
        font-size: 4vw;
    }
    .color-block {
        right: auto;
        bottom: 70%;
    }
    .links{
        padding-left: 7vw;
        font-size: 20vw;
    }
    .cb-down.color-block.move-in-down {
        z-index: 5;
    }
    .cb-white {
        z-index: 1;
    }
    .cb-blue {
        z-index: 0;
    }
    .content-container {
        width: 100%;
        /* display: flex;
        flex-direction: column;
        justify-content: center; */
    }
    .content-title {
        font-size: 15vw;
        padding-left: 6vw;
        padding-top: 4rem;
        padding-bottom: 1rem;
    }
    .desc-title-container {
        display: none;
    }
    .work-content-text {
        line-height: 2rem;
        font-size: 4vw;
        padding-left: 10vw;
        padding-right: 10vw;
    }
    .content-section {
        overflow: initial;
    }
    .content-hidden {
        display: none;
    }
    .work-content-title-text {
        font-size: 8vw;
        padding-left: 10vw;
        padding-right: 10vw;
        padding-bottom: 3rem;
    }
    .work-content-table {
        padding-left: 10vw;
        padding-right: 10vw;
        font-size: 4vw;
        flex-direction: column;
    }
    .content-table-item {
        padding-top: 5vw;
        padding-right: 0rem;
        width: 100%;
    }
    .work-content-table-title {
        font-size: 4vw;
    }
    .work-content-table-text {
        font-size: 3.5vw;
    }
    .tweet-img-row {
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .work-img {
        padding-left: 5%;
        padding-right: 5%;
    }

    .floating-back-btn {
        width: 7.5vw;
        height: 7.5vw;
        border-radius: 1000px;
        z-index: 15;
        background-color: #000000;
        justify-content: center;
        align-items: center;
        margin-left: 5vw;
        border-color: #ffffff;
        border-style: solid;
        border-width: 1px;
        display: flex;
    }
    
    .floating-back-btn.floating-active {
        transform: scale(1,1);
    }

    .floating-back-icon {
        height: 4vw;
    }

    .contact-item {
        width: 70%;
        height: 8%;
    }

    .contact-text {
        font-size: 6vw;
    }

    .about-container {
        height: auto;
        min-height: auto;
    }

    .about-text {
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 5vw;
        font-size: 5vw;
    }

    .about-img {
        padding-top: 12vh;
        padding-bottom: 5vh;
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .resume-btn-container {
        padding-bottom: 5vw;
    }

    .content-title:first-child {
        padding-top: 5rem;
    }

    .content-title:last-child {
        padding-bottom: 4rem;
    }

    .bottom-left {
        padding-bottom: 2vw;
    }

    .floating-bar {
        width: 100%;
        height: 7.5vh;
        background-color: #e8e9e8;
        position: fixed;
        top: 0%;
        z-index: 14;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
    }
    
    .bar-text {
        font-family: 'Gilroy-Bold';
        font-size: 5vw;
        padding-right: 5vw;
    }
    
    .floating-bar.active-bar {        
        transform: scale(1,1);
    }

    .password-wrapper {
        background-color: #39536d;
    }

    .password-modal {
        filter: opacity(0.999);
    }

}
