html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Gilroy-Bold;
  src: url("Gilroy-Bold.7e9c5e32.ttf");
}

@font-face {
  font-family: Gilroy-BoldItalic;
  src: url("Gilroy-BoldItalic.77ad71a8.ttf");
}

@font-face {
  font-family: Gilroy-SemiBold;
  src: url("Gilroy-SemiBold.0b658f11.ttf");
}

@font-face {
  font-family: Gilroy-SemiBoldItalic;
  src: url("Gilroy-SemiBoldItalic.acb78385.ttf");
}

@font-face {
  font-family: Gilroy-Medium;
  src: url("Gilroy-Medium.75b53dfb.ttf");
}

@font-face {
  font-family: Gilroy-MediumItalic;
  src: url("Gilroy-MediumItalic.fc43dee7.ttf");
}

@font-face {
  font-family: Gilroy-Regular;
  src: url("Gilroy-Regular.0994b6fe.ttf");
}

@font-face {
  font-family: Gilroy-RegularItalic;
  src: url("Gilroy-RegularItalic.161a3921.ttf");
}

@font-face {
  font-family: Gilroy-Light;
  src: url("Gilroy-Light.d9fcc2d5.ttf");
}

@font-face {
  font-family: Gilroy-LightItalic;
  src: url("Gilroy-LightItalic.f273c8ed.ttf");
}

.site-wrapper {
  height: 100vh;
  width: 100%;
  background: #f7f5f3;
  display: flex;
}

.left-column {
  overflow: hidden;
}

.color-block {
  height: 100%;
  width: 100%;
  transition: all .3s cubic-bezier(1, .02, .38, .71);
  position: fixed;
  bottom: 50%;
  right: 60%;
}

.color-block.cb-down {
  display: flex;
  bottom: -100%;
  right: -100%;
}

.cb-blue {
  background: #d0e3f5;
}

.cb-white {
  background: #f7f5f3;
}

.z10 {
  z-index: 10;
}

.z9 {
  z-index: 9;
}

.z8 {
  z-index: 8;
}

.z7 {
  z-index: 7;
}

.z6 {
  z-index: 6;
}

.z5 {
  z-index: 5;
}

.z4 {
  z-index: 4;
}

.z3 {
  z-index: 3;
}

.z2 {
  z-index: 2;
}

.z1 {
  z-index: 1;
}

.color-block.move-in {
  bottom: 0%;
  right: 0%;
}

.color-block.move-out {
  bottom: 100%;
  right: 100%;
}

.color-block.move-in-down {
  bottom: 0%;
  right: 0%;
}

.left-column {
  height: 100%;
  width: 40%;
}

.right-column {
  height: 100%;
  width: 60%;
  overflow: auto;
}

.top-left {
  height: 50%;
}

.bottom-left {
  height: 50%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.name-space {
  width: max-content;
  height: max-content;
  padding-bottom: 1.4rem;
  padding-left: 4rem;
  font-family: Gilroy-Medium;
  font-size: 4rem;
}

.tag-line {
  -webkit-user-select: none;
  user-select: none;
  padding-left: 4rem;
  font-family: Gilroy-Light;
  font-size: 1.5rem;
}

.links {
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 8rem;
  font-family: Gilroy-SemiBold;
  font-size: 10rem;
  display: flex;
}

.content-title {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 2rem;
  padding-left: 8rem;
  font-family: Gilroy-SemiBold;
  font-size: 6.5rem;
  display: flex;
}

.content-title:first-child {
  padding-top: 5rem;
}

.content-title:hover {
  -webkit-text-decoration: underline #fff;
  text-decoration: underline #fff;
}

.spacer {
  height: 6%;
}

.link-item {
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  transition: transform .2s;
}

.link-item:hover {
  -webkit-text-decoration: underline #2395ff30;
  text-decoration: underline #2395ff30;
  text-decoration-thickness: 8px;
  transform: scale(1.04);
}

.hidden {
  display: none;
}

.send-b-right {
  bottom: -100%;
  right: -100%;
}

.send-b-in.content-container {
  bottom: 0%;
  right: 0%;
}

.send-b-in.desc-title-container {
  bottom: 0%;
  right: 60%;
}

.p-fixed {
  position: fixed;
}

.content-container {
  width: 60%;
  height: 100%;
  transition: all .3s cubic-bezier(1, .02, .38, .71);
  overflow: auto;
}

.desc-title-container {
  width: 40%;
  height: 50%;
  flex-direction: column;
  justify-content: center;
  transition: all .3s cubic-bezier(1, .02, .38, .71);
  display: flex;
}

.title-test {
  align-items: center;
}

.desc-title-content {
  width: max-content;
  height: 60%;
  -webkit-user-select: none;
  user-select: none;
  align-items: end;
  padding-left: 4rem;
  font-family: Gilroy-Medium;
  font-size: 4rem;
  display: flex;
}

.content-section.content-hidden {
  max-height: 0;
}

.content-section {
  background-color: #000;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.work-content-title-text {
  color: #fff;
  padding: 2rem 8rem;
  font-family: Gilroy-Medium;
  font-size: 3.2rem;
}

.work-content-text {
  color: #fff;
  padding-bottom: 3rem;
  padding-left: 8rem;
  padding-right: 8rem;
  font-family: Gilroy-Medium;
  font-size: 1.5rem;
}

.work-content-text a {
  color: #d0e3f5;
}

.work-content-table {
  flex-direction: row;
  padding-bottom: 3rem;
  padding-left: 8rem;
  padding-right: 8rem;
  display: flex;
}

.work-content-table-title {
  color: #fff;
  padding-bottom: 1.4rem;
  font-family: Gilroy-Medium;
  font-size: 1.5rem;
  text-decoration: underline;
}

.work-content-table-text {
  color: #fff;
  padding-bottom: 1.4rem;
  font-family: Gilroy-Medium;
  font-size: 1.5rem;
}

.content-table-item {
  width: 33%;
  flex-direction: column;
  padding-right: 1.5rem;
  display: flex;
}

.work-img {
  justify-content: center;
  padding-bottom: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
}

.work-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border: 2px solid #fff;
  border-radius: 5px;
}

.tweet-img-row {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
  display: flex;
}

.content-title.title-selected {
  background-color: #f7f5f3;
  font-family: Gilroy-SemiBoldItalic;
}

.content-title.title-selected:hover {
  text-decoration: none;
}

.my-name {
  -webkit-user-select: none;
  user-select: none;
  cursor: auto;
}

.about-img {
  justify-content: center;
  padding: 2rem 5rem 3rem;
  display: flex;
}

.about-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 15rem;
  border-radius: 1000px;
}

.about-text {
  padding-bottom: 3rem;
  padding-left: 8rem;
  padding-right: 8rem;
  font-family: Gilroy-Medium;
  font-size: 1.5rem;
}

.about-container {
  height: auto;
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.resume-btn {
  width: 9rem;
  height: 3rem;
  background-color: #000;
  justify-content: center;
  align-items: center;
  transition: transform .2s;
  display: flex;
}

.resume-btn:hover {
  transform: scale(1.04);
}

.resume-btn-container {
  width: 100%;
  justify-content: center;
  padding-bottom: 2rem;
  display: flex;
}

.resume-btn-container a {
  color: #fff;
  font-family: Gilroy-Light;
  font-size: 1.8rem;
  text-decoration: none;
}

.contact-container {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.contact-text {
  font-family: Gilroy-Medium;
  font-size: 1.5rem;
}

.contact-item {
  width: 20rem;
  height: 4rem;
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  transition: transform .2s;
  display: flex;
}

.contact-item:hover {
  transform: scale(1.04);
}

.contact-item:last-child {
  margin-bottom: 0;
}

.contact-item a {
  color: #fff;
  width: 100%;
  height: 100%;
}

.contact-item-link {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.return-container {
  -webkit-user-select: none;
  user-select: none;
  height: 40%;
  align-items: end;
  padding-bottom: 1rem;
  padding-left: 4rem;
  font-family: Gilroy-Regular;
  font-size: 1.5rem;
  display: flex;
}

.return-btn {
  cursor: pointer;
  flex-direction: row;
  transition: transform .2s;
  display: flex;
}

.return-btn img {
  padding-right: .75rem;
}

.return-text {
  align-items: center;
  display: flex;
}

.return-btn:hover {
  transform: scale(1.04);
}

.back-svg {
  height: 32px;
}

img {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
}

.floating-back-btn {
  transition: all .5s cubic-bezier(.075, .82, .165, 1);
  transform: scale(0);
}

.floating-bar {
  transition: all .1s cubic-bezier(.075, .82, .165, 1);
  display: none;
  transform: scale(1, 0);
}

.password-wrapper {
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: #596f85;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.password-modal {
  z-index: 30;
  width: 35rem;
  height: 15rem;
  filter: drop-shadow(0 4px 7px #00000040);
  background-color: #39536d;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.password-desc {
  color: #fff;
  padding-top: 2rem;
  font-family: Gilroy-Medium;
  font-size: 2rem;
}

.lock-svg {
  height: 2rem;
}

.password-entry {
  width: 100%;
}

.password-entry input {
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 8px 0 0 8px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  font-family: Gilroy-Medium;
  font-size: 1.3rem;
}

.password-entry input:focus {
  box-shadow: none;
  outline-offset: 0px;
  border: none;
  outline: none;
}

.form-area {
  width: 100%;
  flex-direction: row;
  padding-top: 3rem;
  display: flex;
}

.password-submit-btn input {
  display: none;
}

.password-submit-btn {
  height: 30px;
  width: 18%;
  cursor: pointer;
  background-color: #0e1d2c;
  border-radius: 0 8px 8px 0;
  justify-content: center;
  align-items: center;
  transition: all .5s;
  display: flex;
}

.password-submit-btn:hover {
  background-color: #596f85;
}

.submit-svg {
  height: 20px;
  cursor: pointer;
  padding: 5px 25px;
}

.pass-label {
  justify-content: center;
  align-items: center;
  display: flex;
}

.password-main-form {
  width: 80%;
}

.password-error-msg {
  color: #ffd78c;
  font-family: Gilroy-Medium;
  position: absolute;
  top: 35%;
}

.password-wrapper.password-active {
  display: none;
}

@media (max-width: 920px) {
  html, body {
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .site-wrapper {
    flex-direction: column;
    overflow: hidden;
  }

  .left-column {
    width: 100%;
    height: 30%;
    align-items: end;
    padding-left: 7vw;
    display: flex;
  }

  .right-column {
    width: 100%;
    height: 70%;
  }

  .top-left {
    display: none;
  }

  .name-space {
    padding-left: 0;
    font-size: 11vw;
  }

  .tag-line {
    padding-left: 0;
    font-size: 4vw;
  }

  .color-block {
    bottom: 70%;
    right: auto;
  }

  .links {
    padding-left: 7vw;
    font-size: 20vw;
  }

  .cb-down.color-block.move-in-down {
    z-index: 5;
  }

  .cb-white {
    z-index: 1;
  }

  .cb-blue {
    z-index: 0;
  }

  .content-container {
    width: 100%;
  }

  .content-title {
    padding-top: 4rem;
    padding-bottom: 1rem;
    padding-left: 6vw;
    font-size: 15vw;
  }

  .desc-title-container {
    display: none;
  }

  .work-content-text {
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: 4vw;
    line-height: 2rem;
  }

  .content-section {
    overflow: initial;
  }

  .content-hidden {
    display: none;
  }

  .work-content-title-text {
    padding-bottom: 3rem;
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: 8vw;
  }

  .work-content-table {
    flex-direction: column;
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: 4vw;
  }

  .content-table-item {
    width: 100%;
    padding-top: 5vw;
    padding-right: 0;
  }

  .work-content-table-title {
    font-size: 4vw;
  }

  .work-content-table-text {
    font-size: 3.5vw;
  }

  .tweet-img-row {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .work-img {
    padding-left: 5%;
    padding-right: 5%;
  }

  .floating-back-btn {
    width: 7.5vw;
    height: 7.5vw;
    z-index: 15;
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 1000px;
    justify-content: center;
    align-items: center;
    margin-left: 5vw;
    display: flex;
  }

  .floating-back-btn.floating-active {
    transform: scale(1);
  }

  .floating-back-icon {
    height: 4vw;
  }

  .contact-item {
    width: 70%;
    height: 8%;
  }

  .contact-text {
    font-size: 6vw;
  }

  .about-container {
    height: auto;
    min-height: auto;
  }

  .about-text {
    padding-bottom: 5vw;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 5vw;
  }

  .about-img {
    padding: 12vh 10vw 5vh;
  }

  .resume-btn-container {
    padding-bottom: 5vw;
  }

  .content-title:first-child {
    padding-top: 5rem;
  }

  .content-title:last-child {
    padding-bottom: 4rem;
  }

  .bottom-left {
    padding-bottom: 2vw;
  }

  .floating-bar {
    width: 100%;
    height: 7.5vh;
    z-index: 14;
    background-color: #e8e9e8;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0%;
    overflow: hidden;
  }

  .bar-text {
    padding-right: 5vw;
    font-family: Gilroy-Bold;
    font-size: 5vw;
  }

  .floating-bar.active-bar {
    transform: scale(1);
  }

  .password-wrapper {
    background-color: #39536d;
  }

  .password-modal {
    filter: opacity(.999);
  }
}

/*# sourceMappingURL=index.7b7b519c.css.map */
